import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Icon, Spacing, Text } from '@reservamos/elements';
import HourTag from 'ui/atoms/HourTag';

const NextHoursRange = ({ border, padding, icon }) => {
  const { t } = useTranslation();

  return (
    <Box
      paddingHorizontal={padding ? 'S' : ''}
      paddingVertical="XS"
      borderRadius="L"
      border={border ? 'all' : ''}
      borderColor="grayBorder"
    >
      <Spacing justifyContent="space-between" responsiveSize="XXS">
        <Text weight="semibold" size="S" xsMobileSize="XS">
          {t('general:next_departures')}
        </Text>

        <Spacing alignItems="center" justifyContent="space-between">
          <HourTag icon="EarlyMorning" label="6:10 am" bgColor="transparent" />

          <Text size="XXS" color="grayMedium">
            {t('general:next_departures_range')}
          </Text>

          <Spacing size="XS" alignItems="center">
            <Icon type="Morning" size="S" color="grayMedium" />
            <HourTag icon="" label="9:45 pm" bgColor={icon ? 'grayMedium' : 'transparent'} />
            {icon && <Icon type="ChevronRight" size="S" color="grayMedium" />}
          </Spacing>
        </Spacing>
      </Spacing>
    </Box>
  );
};

NextHoursRange.propTypes = {
  border: PropTypes.bool,
  padding: PropTypes.bool,
  icon: PropTypes.bool,
};

NextHoursRange.defaultProps = {
  border: false,
  padding: false,
  icon: false,
};

export default NextHoursRange;
