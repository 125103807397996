import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Icon, Box, BlushingBackground } from '@reservamos/elements';
import { useDispatch } from 'react-redux';
import { setPassengers } from '@/actions/purchase';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import usePurchase from '../../hooks/store/usePurchase';
import passengerTypes from '../../config/passengerTypes';
import PassengerQuantitySelector from '../../ui/atoms/PassengerQuantitySelector';

/**
 * PassengerSelectorBox component.
 * Renders a passenger selector for different categories for bulk purchases.
 */
function PassengerSelectorBox() {
  const { t } = useTranslation('passengers');
  const dispatch = useDispatch();
  const { token, passengers, departs } = usePurchase();
  const { passengerTypes: rawCategoryDetails } = departs.fragments[0];

  // changes the category type to pcd if it is special
  // because ui purposes expects pcd
  const categoryDetails = rawCategoryDetails.map((category) => ({
    ...category,
    type: category.type === 'special' ? 'pcd' : category.type,
  }));

  const { SEAT_SELECTION_LIMIT } = useWhitelabelFeatures();

  const passengerStructure = {
    lastName: 'NA',
    gender: '',
    phoneCountry: 'MX',
    name: 'NA',
    wantsOutgoingInsurance: false,
    phone: 'NA',
    wantsIncomingInsurance: false,
    secondLastName: '',
    documentType: null,
    documentId: 'NA',
    nationalityId: 1,
    dateOfBirth: null,
    phoneCode: '52',
    firstName: 'NA',
    email: 'NA',
    isoCountryCode: null,
    category: 'adult',
  };

  /**
   * Checks if there is an adult passenger.
   */
  const hasAdultPassenger = () => {
    return passengers.some(
      (passenger) => passenger.busCategory !== 'minor' && passenger.busCategory !== 'student',
    );
  };

  /**
   * Handles passanger status update
   */
  const handlePassengerChange = (type) => {
    const newPassengers = [...passengers];

    if (!hasAdultPassenger() && type === 'minor') {
      return;
    }

    const newPassenger = {
      ...passengerStructure,
      busCategory: type === 'pcd' ? 'special' : type,
    };
    newPassengers.push(newPassenger);
    dispatch(setPassengers(newPassengers, token));
  };

  /**
   * Removes indicated passanger from status array
   */
  const clearPassenger = (type) => {
    if (passengers.length === 0) return;
    let newPassengers = [...passengers];

    const indexWithCategoryToRemove = newPassengers.findLastIndex(
      (passenger) =>
        passenger.busCategory === type || (type === 'pcd' && passenger.busCategory === 'special'),
    );
    if (indexWithCategoryToRemove !== -1 && newPassengers.length > 0) {
      newPassengers.splice(indexWithCategoryToRemove, 1);
    }

    const hasRemainingAdultPassenger = newPassengers.some(
      (passenger) => passenger.busCategory !== 'minor' && passenger.busCategory !== 'student',
    );

    if (!hasRemainingAdultPassenger) {
      newPassengers = newPassengers.filter((passenger) => passenger.busCategory !== 'minor');
    }

    dispatch(setPassengers(newPassengers, token));
  };

  /**
   * Handles disabled status for specific category
   * checks if the category is available or if the limit has been reached
   */
  const handleDisabled = (type) => {
    if (passengers.length === SEAT_SELECTION_LIMIT) return true;

    const category = categoryDetails.find((busCategory) => busCategory.type === type);
    if (category?.availability === 0) return true;

    const currentPassengersOfType = passengers.filter(
      (passenger) =>
        passenger.busCategory === type || (type === 'pcd' && passenger.busCategory === 'special'),
    ).length;
    return currentPassengersOfType >= category?.availability;
  };

  const staticPassengerTypes = ['general', 'older', 'minor', 'pcd', 'student'];

  return (
    <Spacing size="S" vertical>
      <Spacing alignItems="center" justifyContent="space-between">
        <Text size="L" weight="bold">
          {t('selector.who_are_traveling')}
        </Text>
        <Text mobileSize="S">{`${passengers.length} ${t('on_board')}`}</Text>
      </Spacing>

      <div className="relative overflow-hidden rounded-[20px] bg-white/15">
        <div className="relative z-[1] flex flex-col gap-[15px] p-[10px]">
          {staticPassengerTypes.map((type) => (
            <PassengerQuantitySelector
              key={type}
              type={type}
              counter={
                passengers.filter(
                  (passenger) =>
                    passenger.busCategory === type ||
                    (type === 'pcd' && passenger.busCategory === 'special'),
                ).length ?? 0
              }
              onClickIncrement={() => handlePassengerChange(type)}
              onClickDecrement={() => clearPassenger(type)}
              isDisabled={handleDisabled(type)}
            />
          ))}

          <Spacing size="S" alignItems="center">
            <Spacing size="XS" alignItems="center">
              <Icon type="BlockNotAvailable" size="S" />
              <Text mobileSize="S">{t('selector.off_season')}</Text>
            </Spacing>

            <Box
              paddingHorizontal="S"
              paddingVertical="XS"
              bgColor="grayMedium"
              alphaBg="XS"
              borderRadius="L"
            >
              <Spacing justifyContent="center" alignItems="center">
                {categoryDetails
                  .filter((category) => category.availability === 0)
                  .map((category) => (
                    <Text key={category.type} color="grayMedium" weight="semibold">
                      {t(passengerTypes[category.type].label)}
                    </Text>
                  ))}
              </Spacing>
            </Box>
          </Spacing>
        </div>

        <BlushingBackground hideMiddle />
      </div>
    </Spacing>
  );
}

export default PassengerSelectorBox;
